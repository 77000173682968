@import '../../sass/utils.scss';

.profile {
    margin: 100px 20px;
    font-family: $title-font;
    &__container {
        margin: 30px 0
    }
    &__container .input {
        margin: 10px 0;
    }
    & button {
        height: 60px;
        margin: 10px 0;
        font-size: 16px;
        font-weight: 600;
    }
}