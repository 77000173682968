@import '../../../sass/utils.scss';

.bienestar {
    margin: 100px 20px 0 ;
    font-family: $title-font;
    &__container {
        margin-top: 10px;
        
    }
    &__container p {
        margin-bottom: 20px;
    }
    &__container h3 {
        margin-bottom: 20px;
        text-align: center;
    }
    &__textArea  {
        margin: 20px 0;
    }
    &__textArea textarea {
        width: 100%;
        height: 100px;
        padding: 10px 12px;
        font-family: $paragraph-font;
        font-size: 16px;
        border: 1px solid rgb(184, 184, 184);
        border-radius: 10px;
        color: $black-color-first;
        background-color: $brand-white;
    }
    &__btn button {
        height: 60px;
        font-size: 16px;
        font-weight: 600;
    }
}