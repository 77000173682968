@import '../../sass/utils.scss';

.input__container {
    font-family: $title-font;
}
.input, select {
    width: 100%;
    height: 60px;
    padding: 0 12px;
    font-family: $paragraph-font;
    font-size: 16px;
    border: 1px solid rgb(184, 184, 184);
    border-radius: 10px;
    color: $black-color-first;
    background-color: $brand-white;
}  
input::placeholder {
    padding: 0 12px;
    font-size: 16px;
    font-family: $paragraph-font;
    color: $black-color-fourth;
}
input:focus {  
    padding: 0 12px;
    font-size: 16px;
    font-family: $paragraph-font;
    color: $black-color-first;
} 