@import '../../sass/utils.scss';

.form {
    margin: 100px 20px;
    font-family: $title-font;
    &__container h5 { 
        margin-top: 20px;
        font-size: 20px;
    }
    &__container select {
        margin: 10px 0;
    }
    &__btn {
        margin-top: 30px;
    }
    &__btn button {
        height: 60px;
        font-size: 18px;
    }
}