@import '../../sass/utils.scss';

.topbar {
    position: fixed;
        top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:10px 5%;
    height: 70px;
    width: 100%;
    font-family: $title-font;
    font-weight: 600;
    background-color: $white-color-first;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
    & span {
        color: $brand-green;
    }
    & ul {
        list-style: none;
    }
    & li {
        font-size: 12px;
    }
}