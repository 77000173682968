@import '../../sass/utils.scss';

.navbar {
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 80px;
    background-color: rgb(255, 251, 251);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        min-width: 50px;
        overflow: hidden;
        white-space: nowrap;
        font-family: $title-font;
        font-size: 13px;
        color: $brand-purple;
        font-weight: 400;
        text-decoration: none;
        -webkit-tap-highlight-color: transparent;
    }
}