@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;600;700&display=swap');

$title-font: 'Poppins', sans-serif;
$paragraph-font: 'Lato', sans-serif;
$brand-font: sans-serif;

$brand-purple: #6100DD;
$brand-purple-alt: #686CD4;
$brand-green: #53d5d5;
$brand-green-alt: #64BACC;
$brand-orange: #d88978;
$brand-yellow: #FCD454;
$brand-white: #F2F3FA;

$white-color-first: #ffffff;
$white-color-second: #ECECED;
$white-color-third: #e2dede;
$white-color-fourth: #cac7c7;

$black-color-first: #1A171B;
$black-color-second: #3F3D3D;
$black-color-third: #58585A;
$black-color-fourth: #727171cb;

@mixin flex($align, $justify, $direction: row) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $direction;
}

@mixin title($size, $color) {
  font-size: $size;
  color: $color;
  font-family: $title-font;
}

@mixin paragraph($size, $color) {
  font-family: $paragraph-font;
  font-size: $size;
  color: $color;
}

@mixin btn-primary($width, $height) {
  background: linear-gradient(180deg, #F35480 0%, #FF4D00 100%);
  color: $white-color-first;
  width: $width;
  height: $height;
  font-family: $paragraph-font;
  border-radius: 10px;
  font-size: .8em;
  &:hover {
    color: $brand-color;
    background: $white-color-first;
    border: 1px solid $brand-color;
  }
}

@mixin btn-secondary($width, $height) {
  background: $white-color-first;
  color: $brand-color;
  border: 1px solid $brand-color;
  box-sizing: border-box;
  border-radius: 10px;
  width: $width;
  height: $height;
  font-family: $paragraph-font;
  font-size: .8em;
  &:hover {
    color: $white-color-first;
    background:  linear-gradient(180deg, #F35480 0%, #FF4D00 100%);
  }
}

@mixin overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: transparentize($black-color-first, .9);
  backdrop-filter: blur(10px);
  @include flex(center, center);
}

