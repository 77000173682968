@import '../../sass/utils.scss';

.login {
    height: 100vh;
    background-color: rgb(250, 250, 250);
    font-family: $title-font;
    overflow: hidden;
    &__container {
        margin: 200px 20px;
    }
    &__container span {
        color: $brand-green;
    }
    &__container input {
        height: 50px;
        width: 100%;
    }
    &__btn {
        margin: 30px 0;
    }
    &__btn button {
       height: 50px;
       font-size: 16px;
       font-weight: 600;
       background-color: $brand-green;
    }
    &__btn p {
        margin: 10px 0px;
        text-align: center;
    }
    &__btn a {
        text-decoration: none;
        color: $brand-purple;
    }
    &__btn a:hover {
        color: $brand-green;
        font-weight: 600;
        text-decoration: un underline;
    }
    & h3 {
        margin: 10px 0;
        font-size: 16px;
        color: red;
        text-align: center;
        font-weight: 600;
    }
}